<template>
	<div>
		<!-- 头部logo -->
		<headLogo @navigation="navigation"></headLogo>
		
		<!-- 头部导航 -->
		<navigation :headTitle="headTitle" :headImg="headImg" style="margin-top:96px;"></navigation>
		
		<div class="theater">
			<el-row class="theater-site">
				<div :class="[this.value==1?'theater-yes':'theater-no']">
					<div @click="landmark">
						FLAGSHIP THEATRES
					</div>
				</div>
				<div :class="[this.value==2?'selectyes':'selectno']">
					<div @click="beijing">
						BEIJING-TIANJIN-HEBEI
					</div>
				</div>
				<div :class="[this.value==3?'selectyes':'selectno']">
					<div @click="east">
						EASTERN CHINA
					</div>
				</div>
				<div :class="[this.value==4?'selectyes':'selectno']">
					<div @click="south">
						SOUTHERN CHINA
					</div>
				</div>
				<div :class="[this.value==5?'selectyes':'selectno']">
					<div @click="central">
						CENTRAL CHINA
					</div>
				</div>
				<div :class="[this.value==6?'north-yes':'north-no']">
					<div @click="north">
						NORTHERN CHINA
					</div>
				</div>
			</el-row>
			
			<!-- 地图 -->
			<el-row>
				<el-row v-if="notData" style="display:flex;flex-direction:column;align-items:center;">
					<el-row class="theater-box" v-for="(i,index) in ListData" :key="index">
						<el-row class="theaterboxheight">
							<el-carousel height="470px" arrow="always">
								<el-carousel-item v-for="(item,idx) in img[index]" :key="idx">
									<template v-if="item && item.pictureUrl">
										<img :src="theaterNotLoadImage" :onload="onLoadImg(item.pictureUrl)" class="slideshow-img"/>
									</template>
									
								</el-carousel-item>
							</el-carousel>
						</el-row>
						<el-row class="theater-title">
							{{i.theaterName}}
						</el-row>
						<el-row class="theater-message" v-html="i.bodyContent">
							{{i.bodyContent}}
						</el-row>
					</el-row>
					<el-row>
						<button v-if="loading" class="productions-btn" @click="load">Load More</button>
					</el-row>
				</el-row>
				<el-row v-else>
					<el-row>
						<el-image src="/index/nodata.png" class="error-img"></el-image>
					</el-row>
					<el-row class="error-message">
						Empty data
					</el-row>
				</el-row>
			</el-row>
		</div>
			
		
		<!-- 菜单导航 -->
		<meauDialog :meauValue="navigationvalue" @meauFn="menu"></meauDialog>
		
		<!-- 底部导航 -->
		<bottomNavigation></bottomNavigation>
	</div>
</template>

<script>
// 图片查询接口
import { selectPictureListById , selectContentListById } from '@/api/index.js'
	// 头部组件
	import headLogo from '@/components/headLogo.vue'
	// 头部导航
	import navigation from '@/components/navigation.vue'
	// 底部导航
	import bottomNavigation from '@/components/bottomNavigation.vue'
	// 菜单导航
	import meauDialog from '@/components/meauDialog.vue'
	export default
	{
		data()
		{
			return{
				// 头部导航标题
				headTitle:'THEATRES',
				// 头部导航图片
				headImg:'/index/school.png',
				value:1,
				// 导航值
				navigationvalue:false,
				
				// 地标默认显示条数
				count:5,
				// 加载按钮
				loading:true,
				
				// 全部数据
				slideshowData:[],
				sildeData:{},
				notData:true,
				
				img:[],
				
				moduleType:'MODULE_LANDMARK',
				theaterNotLoadImage: "/PCimage/800_450.jpg", //影院图片未加载出来
			}
		},
		components:
		{
			headLogo,
			navigation,
			bottomNavigation,
			meauDialog
		},
		created()
		{
			if(this.$route.query.item==undefined)
			{
				this.value=1;
			}
			else
			{
				if(this.$route.query.item.moduleId==10)
				{
					this.value=1;
				}
				else if(this.$route.query.item.moduleId==11)
				{
					this.value=2;
				}
				else if(this.$route.query.item.moduleId==12)
				{
					this.value=3;
				}
				else if(this.$route.query.item.moduleId==13)
				{
					this.value=4;
				}
				else
				{
					this.value=5;
				}
			}
			this.getList();
		},	
		computed:
		{
			// 计算属性
			ListData()
			{
				let data=[];
				if(this.slideshowData.length>5)
				{
					data=this.slideshowData.slice(0,this.count)
					return data;
				}
				else
				{
					data=this.slideshowData;
					this.loading=false;
					return data;
				}
			}
		},
		methods:
		{
			// 头部传来的数据
			navigation(v)
			{
				this.navigationvalue=v;
				console.log(this.navigationvalue,'头部传递菜单导航')
			},
			// 遮罩层传回来的数据
			menu(v)
			{
				this.navigationvalue=v;
			},
			
			// 加载更多
			load()
			{
				this.count+=5;
				if(this.count>=this.slideshowData.length)
				{
					this.loading=false
				}
			},
		
			
			// 全部数据
			getList()
			{
				selectContentListById(
				{
				  chnanelType: 1,
				  isIndex: 0,
				  moduleType: this.moduleType
				})
				.then((response)=>
				{
					this.img=[]
					if(response.data.data=='')
					{
						this.notData=false;
						this.slideshowData = []
					}
					else
					{
						this.notData=true;
						this.loading=true;
						this.slideshowData=response.data.data;
						console.log(this.slideshowData, '00000')
						this.slideshowData.map((v)=>
						{
							if(v.pictureUrl)
							{
								var pictureUrl={'pictureUrl':v.pictureUrl}
							}
							if(v.pictureUrl2!=null)
							{
								var pictureUrl2={'pictureUrl':v.pictureUrl2}
							}
							if(v.pictureUrl3!=null)
							{
								var pictureUrl3={'pictureUrl':v.pictureUrl3}
							}
							if(v.pictureUrl4!=null)
							{
								var pictureUrl4={'pictureUrl':v.pictureUrl4}
							}
							if(v.pictureUrl5!=null)
							{
								var pictureUrl5={'pictureUrl':v.pictureUrl5}
							}
							let data=[]
							console.log(pictureUrl5)
							if(pictureUrl5==undefined)
							{
								if(pictureUrl4==undefined)
								{
									if(pictureUrl3==undefined)
									{
										if(pictureUrl2==undefined)
										{
											data.push(pictureUrl)
										}
										else
										{
											data.push(pictureUrl,pictureUrl2)
										}
									}
									else
									{
										data.push(pictureUrl,pictureUrl2,pictureUrl3)
									}
								}
								else
								{
									data.push(pictureUrl,pictureUrl2,pictureUrl3,pictureUrl4)
								}
							}
							else
							{
								data.push(pictureUrl,pictureUrl2,pictureUrl3,pictureUrl4,pictureUrl5)
							}
							this.img.push(data)
						})
					}
				})
			},
			// 地理坐标
			landmark()
			{
				this.value=1;
				this.count=5;
				this.moduleType='MODULE_LANDMARK'
				this.getList();
			},
			// 京津冀
			beijing(){
				this.value=2;
				this.count=5;
				this.moduleType='MODULE_BEIJING'
				this.getList();
			},
			// 华东
			east()
			{
				this.value=3;
				this.count=5;
				this.moduleType='MODULE_EAST'
				this.getList();
			},
			// 华南
			south()
			{
				this.value=4;
				this.count=5;
				this.moduleType='MODULE_SOUTH'
				this.getList();
			},
			// 华中
			central()
			{
				this.value=5;
				this.count=5;
				this.moduleType='MODULE_CENTRAL'
				this.getList();
			},
			// 华北
			north()
			{
				this.value=6;
				this.count=5;
				this.moduleType='MODULE_NORTH'
				this.getList();
			},
			// 图片加载完成时触发
			onLoadImg(imgSrc) 
			{
				return 'this.src='+'"'+imgSrc+'";this.οnlοad=null';
			},
		}
	}
</script>

<style>
	.theater{
		width: 80%;
		margin-left: 10%;
		margin-top: 77px;
	}
	.theater-site{
		height: 50px;
		/* line-height: 50px; */
		border: solid 2px #ccc;
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
		font-size: 16px;
		color: #505050;
		display: flex;
		width: 100%;
	}
	.theater-site>div{display: flex;align-items: center;flex: 1;justify-content: center;}
	.theater-yes{
		height: 46px;
		background-color: #FCD002;
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
	}
	.theater-no{
		height: 46px;
		background-color: #F0EDF1;
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
	}
	.selectyes{
		height: 46px;
		background-color: #FCD002;
	}
	.selectno{
		height: 46px;
		background-color: #F0EDF1;
	}
	.north-yes{
		height: 46px;
		background-color: #FCD002;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
	}
	.north-no{
		height: 46px;
		background-color: #F0EDF1;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
	}
	.theater-data{
		margin-top: 30px;
		text-align: left;
	}
	.slideshow-img{
		width: 980px;
		height: 450px;
		border-radius: 20px;
	}
	.theater-box{
		width: 1170px;
		margin-top: 30px;
		text-align: left;
		overflow: hidden;
	}
	.theater-eastbox{
		margin-top: 30px;
		text-align: left;
	}
	.theater-northbox{
		margin-top: 30px;
		text-align: left;
	}
	.theater-centralbox{
		margin-top: 30px;
		text-align: left;
	}
	.theater-southbox{
		margin-top: 30px;
		text-align: left;
	}
	.theater-title{
		margin-top: 20px;
		font-size: 30px;
		color: #505050;
		text-align: center;
	}
	.theater-message{
		margin-top: 10px;
		line-height: 25px;
		height: 160px;
		/* overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 7;
		-webkit-box-orient: vertical; */
		
		overflow-y:scroll;  /*只是y方向*/
		
		font-size: 14px;
		color: #505050;
		margin-top:30px;
	}
	.theater-message p{
		width: 100%;
		/* min-height: 39px; */
		/* height: 140px; */
	}
	.productions-btn{
		width: 400px;
		height: 50px;
		line-height: 20px;
		border: none;
		border-radius: 50px;
		margin-top: 30px;
		background-color: #FCD002;
		
		font-size: 24px;
		color: #505050;
	}
	.productions-nobtn{
		width: 30%;
		height: 50px;
		border: none;
		border-radius: 50px;
		font-size: 15px;
		margin-top: 50px;
		background-color: #ccc;
		color: #505050;
	}
	.error-img{
		width: 300px;
		height: 200px;
		margin-top: 100px;
	}
	.error-message{
		margin-top: 30px;
		font-size: 20px;
		color: #3B519D;
		margin-bottom: 50px;
	}
	.theaterboxheight{
		height: 500px;
		
	}
	.theaterboxheight img{
		text-align: center;
	}
	.theaterboxheight .el-carousel--horizontal{
		display: inline;
	}
	.theaterboxheight .el-carousel__item{
		text-align: center;
	}
	/* .theaterboxheight .el-carousel__container{
		width: 1000px;
		display: inline-block;
	} */
	.theaterboxheight .el-carousel__arrow--right{
		/* right: -1rem; */
	}
	.theaterboxheight .el-carousel__indicators{
		height: 30px;
		text-align: center;
		bottom: -20px;
		left: 600px;
		margin: 0 auto;
	}
	.el-carousel__indicator button{
		width: 8px !important;
		height: 8px !important;
		background-color: #AAAAAA !important;
	}
	.el-carousel__indicator.is-active button{
		width: 40px !important;
		height: 8px !important;
		background-color: #FDD000 !important;
		border-radius: 4px;
	}
</style>